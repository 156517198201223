export default {
  meg: {
    top: {
      placeholder: "搜索...",
      loginBut:"登录",
      user:"个人信息",
      logout:"退出登录",
      upload:"上传",
      join:"加入会议室",
      meeting:"会议室",
    },
    login:{
      Login:"登录",
      or:"或者",
      BomTopTitle:"用电子邮件继续登录",
      BomTopText:"新用户?",
      BomTopTextSpan:"创建账户",
      MainTopTitleEmail:"电子邮箱地址",
      MainTopTitleCode:"密码",
      RightVoxWeixinBoxText:"微信登录",
      RightVoxWeixinBoxTextScan:"请用微信扫一扫",
      goLogin:'需要登录后才能创建房间'
    },
    logon:{
      LogonTopTitle:"创建账户",
      or:"或者",
      BomTopTitle:"使用电子邮箱注册",
      BomTopText:"已经有账户?",
      BomTopTextSpan:"登录",
      MainTopTitleEmail:"电子邮箱地址",
      MainTopTitleVerification:"验证码",
      TopTitleBoxButSpan:"获取验证码",
      TopTitleBoxButSpanS:" s 后获取",
      MainMiddleButBox:"确定",
      MainTopTitle:"昵称",
      MainTopTitlePass:"密码",
      BottomBoxBut:"确定",
    },
    Krpano:{
      ConferenceHotelText:"创建会议室",
      StarTitleTextTitle:"你将作为该房间的主人",
      StarTitleText:"现在可以开始邀请朋友开始观看作品",
      StarButText:"创建",
    },
    personalcenter:{
      PersonalUploadMainCoverImgText:"最佳尺寸 600×400",
      PersonalUploadMainCoverText:"封面",
      Delete:"删除",
      DeleteMsg:'确定要删除嘛？',
      edit:'编辑',
      Cancel:"取消",
      complete:"完成",
      Publish:"发布",
      one:"",
      onee:"左右立体 比例",
      two:"不建议低于",
      twwo:"上下立体 比例",
      three:"全景非立体 比例",
      du:"六方体立体 比例",
      zhe:"六方非立体 比例",
      Panoramic:"全景",
      and:"",
      eD:"推荐上传",
      Stereoscopic:"",
      Name:"请输入标题",
      Description:"请输入描述",
      max:" 最大尺寸",
      images:"并且立体的画面",
      Personal:"个人主页",
      views:"项目查看次数",
      About:"关于我",
      Membership:"成为会员日期：",
      Edit:"编辑个人信息",
      works:"作品",
      Upload:"上传作品",
      EditiingInputName:"请输入昵称",
      EditiingInputCompany:"所属公司",
      EditiingInputWebsite:"公司或个人网址",
      EditiingInputCity:"地址",
      Img:'图片',
      name:'名称',
      view:'查看',
      replace:'替换'
    },
    edit:{
      hotspot:'热点',
      Sandtable:'沙盘',
      Visibility:'隐私',
      prompt:'文件上传提示',
      perspective:'视角',
      uploadPromptCover:'请上传封面',
      uploadPromptWorks:'请上传作品'
    }
  }
}