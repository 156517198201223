<template>
  <top
    v-if="
      !proxy.url.value.includes('/logon') &&
      !proxy.url.value.includes('/panorama') &&
      !proxy.url.value.includes('/found') &&
      !proxy.url.value.includes('/manage')&&
      !proxy.url.value.includes('/home')
    "
  />
  <router-view></router-view>
</template>

<script setup>
import { watch, ref, getCurrentInstance,onMounted } from "vue";
import top from "@/nav/top.vue";
const { proxy } = getCurrentInstance();
onMounted(()=>{
  document.getElementById('cnzz_stat_icon_1281216336').style.display = 'none';
  
})
</script>

<style>
* {
  font-size: 16px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-family: "acumin";
}
body{
  font-family: "acumin";
}
.el-overlay-dialog {
  width: 100%;
}
</style>
