import { createRouter, createWebHistory } from "vue-router";
const routerHistory = createWebHistory();
import { ElMessage } from "element-plus";
const tinMayer = [
  {
    path: "/",
    component: () => import("../home/main"),
    // redirect:'/upload'
  },
  {
    path: "/nav",
    component: () => import("../nav/top"),
  },
  {
    path: "/logon",
    component: () => import("../nav/register/login"),
  },
  {
    path: "/found",
    component: () => import("../nav/register/logon"),
  },
  {
    path: "/wechat",
    component: () => import("../nav/found"),
  },
  {
    path: "/panorama",
    component: () => import("../krpano/Krpano"),
  },
  {
    path: "/information",
    component: () => import("../personalcenter/PersonalInformation"),
    meta: {
      AuthPolicy: "HomeLogin",
    },
  },
  {
    path: "/edit",
    component: () => import("../edit"),
    meta: {
      AuthPolicy: "HomeLogin",
    },
    children:[{
      path:'/upload',
      name:'upload',
      component:()=>import('../edit/components/upload.vue')
    },
    {
      path:'/hotspot',
      name:'hotspot',
      component:()=>import('../edit/components/hotspot.vue')
    },
    {
      path:'/SandTable',
      name:'SandTable',
      component:()=>import('../edit/components/Sandtable.vue')
    },
    {
      path:'/Visibility',
      name:'Visibility',
      component:()=>import('../edit/components/Visibility.vue')
    },
     {
      path:'/perspective',
      name:'perspective',
      component:()=>import('../edit/components/perspective.vue')
    },
  ]
  },
  {
    // management
    path:'/manage',
    name:'manage',
    component:()=>import('../manage'),
  },
  {
      path:'/home',
      name:'home',
      component:()=>import('../manage/home'),
  },
  {
    path:"/PersonalUpload",
    component:()=>import("../personalcenter/Personalworksupload.vue"),
    meta: {
      AuthPolicy: "HomeLogin",
    },
  },
  {
    path:"/edittools",
    component:()=>import("../EditTools/EditPage")
  },
  {
    path: "/editiing",
    component: () => import("../personalcenter/PersonalInformationEditiing"),
    meta: {
      AuthPolicy: "HomeLogin",
    },
  },
  {
    path: "/login",
    component: () => import("../login/loging"),
  },
  {
    path: "/admin",
    component: () => import("../user/administration"),
    meta: {
      AuthPolicy: "NeedLogin",
    },
    children: [
      {
        path: "administ",
        component: () => import("@/user/list/administrators"),
        meta: {
          AuthPolicy: "NeedLogin",
        },
      },
      {
        path: "pictures",
        component: () => import("@/user/imglist/upload"),
        meta: {
          AuthPolicy: "NeedLogin",
        },
      },
      {
        path: "work",
        component: () => import("@/user/userlist/workslist"),
        meta: {
          AuthPolicy: "NeedLogin",
        },
      },
    ],
  },
  // {
  //   path: "/404",
  //   component: () => import("../views/404"),
  // },
  // {
  //   path: "/:pathMath(.*)",
  //   redirect: "/404",
  // },
];
const router = createRouter({
  history: routerHistory,
  routes: tinMayer,
});
let loginId =null
router.beforeEach((to ,from,next) => {
  const token = localStorage.getItem('v-token')
  if(loginId&&token){
    next(loginId)
    loginId = null
  }
  if(to.path == '/'||to.path == '/logon'||to.path == '/panorama'){
    next();
  }else{
     if(token == null||token == ''){
      next('/logon');
     }else{
      next()
     }
  }
 
  if(from.path=='/panorama'){
    loginId = `/panorama?id=${from.query.id}&encrypt=${from.query.encrypt}&from=1`

    console.log(123,from)
  }
 
})
export default router;
