<template>
  <div class="common-layout" id="common" @scroll="scroll">
    <el-container>
      <el-header id="header">
        <div class="logo" @click="home">
          <div><img :src="logo" /></div>
          <div class="logo-text" id="logoText">Varecloud</div>
        </div>
        <div  id="searchInput" class="main-search" v-if="proxy.url.value == '/' || proxy.url.value == '/information'">
          <el-input  v-model="text" class="search" :placeholder="$t('meg.top.placeholder')" @change="search" />
        </div>
        <el-button id='searchBut' class="search-but" :icon="Search" @click="searchShow" circle />
        <div class="nav-left-box">
          <div class="nav-left-box-LANG">
            <div class="nav-left-box-LANG-text-a" id="CN" @click="ch">CN</div>
            <div class="nav-left-box-LANG-text-b" id="LEN" @click="en">EN</div>
          </div>
          <div class="nav-left" v-if="toux">
            <div class="login" v-if="proxy.url.value == '/'">
              <div class="login-but" @click="login">{{ $t('meg.top.loginBut') }}</div>
            </div>
          </div>
          <div class="nav-lef" v-else>
            <el-popover placement="bottom-end" :width="180" trigger="hover" v-if="avat">
              <div style="text-align: right; margin: 0"></div>
              <div class="bom-title" @click="information">{{ $t('meg.top.user') }}</div>
              <div class="bom-title" @click="logout">{{ $t('meg.top.logout') }}</div>
              <template #reference>
                <div class="nav-lef">
                  <div class="nav-lef-img">
                    <img :src="juMerr?.avatar">
                  </div>
                  <div class="iconfont test-icon-Downarrow xia" id="iconXia"></div>
                </div>
              </template>
            </el-popover>
            <el-popover placement="bottom-end" :width="180" trigger="hover" :visible="avatar" v-else>
              <div style="text-align: right; margin: 0"></div>
              <div class="bom-title" @click="information">{{ $t('meg.top.user') }}</div>
              <div class="bom-title" @click="logout">{{ $t('meg.top.logout') }}</div>
              <template #reference>
                <div class="nav-lef" @click="avatar = true">
                  <div class="nav-lef-img">
                    <img :src="juMerr?.avatar">
                  </div>
                  <div class="iconfont test-icon-Downarrow xia" id="iconXia"></div>
                </div>
              </template>
            </el-popover>
          </div>
          <div>
            <div class="upload-but" @click="PersonalUpload">{{ $t('meg.top.upload') }}</div>
          </div>
          <div>
            <el-popover placement="bottom-start" :width="250" trigger="hover" popper-class="conference-popover"
              v-if="pop">
              <div style="text-align: right; margin: 0"></div>
              <div class="meeting-box-hotel-bom" @click="select">
                <div class="meeting-hotel-img">
                  <img :src="jin" />
                </div>
                <div class="meeting-hotel-shu"></div>
                <div class="meeting-hotel-text">{{ $t('meg.top.join') }}</div>
                <div class="iconfont test-icon-youjiantou meeting-hotel-icon"></div>
              </div>
              <template #reference>
                <div class="logon-but">{{ $t('meg.top.meeting') }}</div>
              </template>
            </el-popover>
            <el-popover placement="bottom-start" :width="250" popper-class="conference-popover" :visible="wtw" v-else>
              <div style="text-align: right; margin: 0"></div>
              <div class="meeting-box-hotel-bom" @click="select">
                <div class="meeting-hotel-img">
                  <img :src="jin" />
                </div>
                <div class="meeting-hotel-shu"></div>
                <div class="meeting-hotel-text">{{ $t('meg.top.join') }}</div>
                <div class="iconfont test-icon-youjiantou meeting-hotel-icon"></div>
              </div>
              <template #reference>
                <div class="logon-but" @click="Rinn">{{ $t('meg.top.meeting') }}</div>
              </template>
            </el-popover>
          </div>
          <el-dialog v-model="dialogTableVisible" :modal="false" :show-close="false" custom-class="dialog">
            <div class="room-nub">
              <input type="text" v-model="roomnum" maxlength="4" class="room-input" autofocus placeholder="ꔷꔷꔷꔷ" />
            </div>
            <div class="number-list">
              <ul class="list-ul">
                <li class="ul-row">
                  <div class="row-box">
                    <ul class="row-first">
                      <li class="first-one" @click="numroom(7)" id="del">
                        <div class="num">7</div>
                      </li>
                      <li class="first-one" @click="numroom(8)">
                        <div class="num">8</div>
                      </li>
                      <li class="first-one" @click="numroom(9)">
                        <div class="num">9</div>
                      </li>
                    </ul>
                    <ul class="row-first">
                      <li class="first-one" @click="numroom(4)">
                        <div class="num">4</div>
                      </li>
                      <li class="first-one" @click="numroom(5)">
                        <div class="num">5</div>
                      </li>
                      <li class="first-one" @click="numroom(6)">
                        <div class="num">6</div>
                      </li>
                    </ul>
                    <ul class="row-first">
                      <li class="first-one" @click="numroom(1)">
                        <div class="num">1</div>
                      </li>
                      <li class="first-one" @click="numroom(2)">
                        <div class="num">2</div>
                      </li>
                      <li class="first-one" @click="numroom(3)">
                        <div class="num">3</div>
                      </li>
                    </ul>
                    <ul class="row-first">
                      <li class="first-one">
                        <div class="
                          iconfont
                          test-icon-xianxingtubiaozhizuomoban-08
                          num-1
                        " @click="del"></div>
                      </li>
                      <li class="first-one" @click="numroom(0)">
                        <div class="num">0</div>
                      </li>
                      <li class="first-one" @click="Ty" :plain="true">
                        <div class="num-2">
                          <div class="num-2-box">In</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </el-dialog>
        </div>
      </el-header>
    </el-container>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
import jin from "@/assets/img/Key icon.png";
import "../font/font_3567303_8683qts7whv/iconfont.css";
import { Search } from "@element-plus/icons-vue";
import logo from "@/assets/img/LOGO.png";
import { useRouter, useRoute } from "vue-router";
import mitter from "../plugin/Bus.js";
import { useI18n } from 'vue-i18n';
const { proxy } = getCurrentInstance();
let text = ref("");
let router = useRouter();
let route = useRoute();
//初始化邮箱获取
let uu = ref("");
let password = ref("");
let code = "";
const twant = ref([]);
let toux = ref(false);
let meeting = ref(false)
const dialogForm = ref(false);
const dialogTableVisible = ref(false);
const strId = proxy.$route.query.id;
let wtw = ref(false)
let pop = ref(false)
let avat = ref(false)
let avatar = ref(false)
let roomnum = ref("")
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//头像区域渲染
const juMerr = ref([]);
function login() {
  proxy.$router.push("/logon");
}
function information() {
  sessionStorage.clear()
  proxy.$router.push("/information");
  avatar.value = false
}
//跳转到上传页面
function PersonalUpload() {
  // proxy.$router.push("/PersonalUpload")
  proxy.$router.push("/upload")
}
if (flag) {
  pop.value = false
  avat.value = false
} else {
  pop.value = true
  avat.value = true
}
//搜索房间
function Rinn() {
  wtw.value = true
  setTimeout(() => {
    wtw.value = false
  }, 1500);
}
//语言切换
const i18n = useI18n()
function ch() {
  i18n.locale.value = "zh";
  CN.style.color = "rgba(102, 33, 199, 0.9)";
  LEN.style.color = "#646464";
  localStorage.setItem("lang", "zh")
}
function en() {
  i18n.locale.value = 'en'
  CN.style.color = "#646464";
  LEN.style.color = "rgba(102, 33, 199, 0.9)";
  localStorage.setItem("lang", "en")
}
//搜索
function search() {
  proxy
    .$Axios({
      method: "get",
      url: "home",
      params: {
        name: text.value,
      },
    })
    .then((res) => {
      twant.value = res.data.data;
      mitter.emit("fn", twant.value);
    });
}
let getNum = () => {
  return roomnum.value == "" && proxy.$route.query.num
    ? proxy.$route.query.num
    : roomnum.value;
};
//显示隐藏搜索框
const searchShow =()=>{
  searchInput.style.display='block';
  searchBut.style.display='none';
}
//加入房间
function select() {
  dialogTableVisible.value = true;
  wtw.value = false
  roomnum.value = "";
}
let ua = navigator.userAgent
//加入会议室
function Ty() {
  proxy
    .$Axios({
      method: "get",
      url: "room/join",
      params: {
        num: getNum(),
        id: strId,
      },
    })
    .then((res) => {
      if (res.data.code == 1000) {
        if (res.data.data.source_id != strId) {
          let war = proxy.$router.resolve({
            path: "panorama",
            query: {
              id: res.data.data.source_id,
              v: "re",
              num: getNum(),
            },
          });
          // if (ua.indexOf("Safari") > -1 && ua.indexOf("Safari") == -1) {
          // } else {
          //   window.open(war.href, "_blank");
          // }
          window.location.href = war.href;
          return;
        }
        localStorage.setItem("tourist", res.data.data.tourist);
        nameNum.value = res.data.data;
        ElMessage({
          message: res.data.msg,
          type: "success",
        });
        letup();
        lettop();
        socketInit();
      } else {
        ElMessage.error(res.data.msg);
        letup();
        lettop();
      }
    });
  dialogTableVisible.value = false;
}
//头像样式
function loginon() {
  proxy
    .$Axios({
      method: "get",
      url: "user/info",
    })
    .then((res) => {
      code = res.data.code;
      if (code == 1000) {
        juMerr.value = res.data.data;
        toux.value = false;
      } else {
        toux.value = true;
        
        localStorage.setItem('v-token','')
      }
    });
}
//点击返还首页
function home() {
  proxy.$router.push("/");
  proxy
    .$Axios({
      method: "get",
      url: "home",
    })
    .then((res) => {
      twant.value = res.data.data;
      mitter.emit("fn", twant.value);
    });
    sessionStorage.clear()
}
//用户退出登录
function logout() {
  proxy
    .$Axios({
      method: "post",
      url: "user/logout",
    })
    .then((res) => {
      code = res.data.code;
      if (code == 1000) {
        ElMessage({
          message: res.data.msg,
          type: "success",
        });
      } else {
        ElMessage.error(res.data.msg);
      }
      localStorage.removeItem("v-token");
      loginon();
    });
}
//房间号点击事件
function numroom(e) {
  if (roomnum.value.length >= 4) {
  } else {
    roomnum.value = roomnum.value + String(e);
  }
}
//绑定退键方法
function back() {
  roomnum.value = roomnum.value.toString().substr(0, roomnum.value.length - 1);
}
//删除输入框点击事件
function del() {
  roomnum.value = roomnum.value.toString().substr(0, roomnum.value.length - 1);
}
//监听键盘事件
document.onkeydown = function (e) {
  let key = window.event.keyCode;
  if (key == 97) {
    numroom(1);
  }
  if (key == 98) {
    numroom(2);
  }
  if (key == 99) {
    numroom(3);
  }
  if (key == 100) {
    numroom(4);
  }
  if (key == 101) {
    numroom(5);
  }
  if (key == 102) {
    numroom(6);
  }
  if (key == 103) {
    numroom(7);
  }
  if (key == 104) {
    numroom(8);
  }
  if (key == 105) {
    numroom(9);
  }
  if (key == 96) {
    numroom(0);
  }
  if (key == 8) {
    back();
  }
};
onBeforeMount(() => {
  loginon();
});
onMounted(() => {
  // if (localStorage.getItem("lang") == "zh") {
  //   CN.style.color = "rgba(64, 140, 244, 0.9)";
  //   LEN.style.color = "#646464";
  // }else{
  //   CN.style.color = "#646464";
  //   LEN.style.color = "rgba(64, 140, 244, 0.9)";
  // }
  // if (localStorage.getItem("lang") == "en") {
    
  // }
});
</script>

<style>
* {
  font-size: 16px;
}

#LEN {
  color: rgba(102, 33, 199, 0.9);
}

/*顶部样式*/
.common-layout {
  width: 100%;
  background: white;
  /* animation: 1s infinite */

}

.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(247, 247, 247);
}

.logo {
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

.logo img {
  width: 45px;
  height: 28px;
  margin-right: 8px;
}

.main-search {
  width: 500px;
  min-width: 72px;
  margin: 0 25px;
}

.main-search .el-input__wrapper {
  border-radius: 25px;
  background: #DCDCDC;
}
::v-deep(.el-input__wrapper .is-focus){
  box-shadow: none!important;
} 
.search .el-input__inner::placeholder {
  color: #646464;
}

.nav-left {
  display: flex;
}

.search-but{
    display: none;
  }
.nav-left-box {
  display: flex;
  align-items: center;
}

.login .el-button.is-round {
  padding: 8px 18px;
}

.logo-text {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 20px;
  font-weight: 600;
}

.login-but {
  width: 50px;
  height: 25px;
  line-height:25px !important;
  text-align: center;
  background: #fff;
  color: rgba(33, 33, 33, 0.7);
  line-height: 16px;
  border-radius: 20px;
  border: 1px solid rgba(245, 247, 250, 0.9);
  padding: 4px 7px;
  font-size: 14px;
  cursor: pointer;
  /* font-family: "Armb"; */
}

.login-but:hover {
  background: #f1f1f1;
  color: #191919;
}

.logon-but {
  width:100px;
  height: 25px;
  line-height:25px;
  text-align: center;
  background: rgba(102, 33, 199, 0.9);;
  border-color: transparent;
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}


.logon-but:hover {
  background: rgba(102, 33, 199, 0.5);
  border-color: transparent;
  color: #fff;
}

.upload-but {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin: 0 10px;
  background: rgba(245, 248, 255, 1);
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  color: rgba(102, 33, 199, 0.9);
  border: 1px solid #eceffe;
  /* font-family: "Armb"; */
  /* font-weight: 600; */
  padding: 3px 7px;
}

.upload-but:hover {
  background: rgba(102, 33, 199, 0.4);
}

/**会议室按钮样式 */
.meeting-box-hotel-bom {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: #f5f8ff;
  border: 1px solid #dee9ff;
  border-radius: 5px;
  padding: 8px 15px;
}

.meeting-hotel-img img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.meeting-hotel-shu {
  height: 25px;
  border-left: 2px solid #dee9ff;
  margin-right: 5px;
}

.meeting-hotel-text {
  flex: 1;
  color: rgba(102, 33, 199, 0.9);
}

.meeting-hotel-icon {
  color: #7ba8ff;
}

/** 头像样式 */
.nav-lef {
  display: flex;
  align-items: center;
}

.nav-lef-img img {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
}

/* 小三角样式*/
.xia {
  display: block;
  font-size: 20px;
  position: relative;
}

.bom-title {
  margin-top: 5px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background: #f5f8ff;
  border: 1px solid #dee9ff;
  border-radius: 5px;
  color: rgba(102, 33, 199, 0.9);;
}

.bom-title:hover {
  background-color: rgba(245, 247, 250, 0.507);
}

/**弹出框样式 */
.dialog header {
  display: none;
}

.dialog {
  position: relative;
  z-index: 9999 !important;
  max-width: 380px;
  min-width: 300px;
  background: #ffffff;
}

.dialog .el-dialog__body {
  padding: 0.008rem;
}

.el-overlay-dialog {
  width: 100%;
}

/**弹出框内部顶部选择 */
.room-top {
  display: flex;
  height: 0.1rem;
  justify-content: center;
}

/**输入框样式 */
.room-nub {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 137px;
}

.room-input {
  background: #ffffff;
  box-shadow: none;
  width: 270px;
  height: 90px;
  color: #494949;
  font-size: 80px;
  text-align: left;
  border: 0;
  padding: 0 5px 0 5px;
  letter-spacing: 30px;
  line-height: normal;
  outline: none;
}

.room-input input:focus {
  border: 0;
}

.room-nub input::-webkit-input-placeholder {
  transform: translate(30px, 0);
}

/**按钮每行样式 */
.row-first {
  display: flex;
  justify-content: center;
  /* margin-top: 15px; */
}

/**按钮盒子样式 */
.num {
  background: #ffffff;
  width: 121px;
  line-height: 84px;
  font-size: 41px;
  color: #969696;
  text-align: center;
  border: 1px solid #e3e3e3;
  cursor: pointer;
}

.num-1 {
  width: 121px;
  line-height: 84px;
  font-size: 41px;
  background: #ffffff;
  color: #969696;
  text-align: center;
  border: 1px solid #e3e3e3;
  cursor: pointer;
}

.num-2 {
  width: 121px;
  height: 84px;
  background: #ffffff;
  color: white;
  text-align: center;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-2-box {
  padding: 5px 35px;
  font-size: 20px;
  border-radius: 20px;
  background-color: #0057ff;
}

.row-box {
  padding-bottom: 5px;
}

.nav-left-box-LANG {
  display: flex;
  margin-right: 15px;

}

.nav-left-box-LANG-text-a {
  margin-right: 10px;
  cursor: pointer;
  font-size:13px ; 
}

.nav-left-box-LANG-text-b {
  margin-right: 10px;
  cursor: pointer;
  font-size:13px ; 
}

@media screen and (max-width: 1400px) {
  .search-but{
    display: none;
  }
  .dialog {
    width: 310px;
    min-height: 390px;
  }

  .room-nub {
    height: 100px;
  }

  .star-room-nub {
    font-size: 74px;
  }

  .num {
    width: 100px;
    line-height: 70px;
  }

  .num-1 {
    width: 100px;
    line-height: 70px;
  }

  .num-2 {
    width: 100px;
    height: 70px;
  }

  .num-2-box {
    font-size: 20px;
    padding: 6px 30px;
  }
}

@media screen and (max-width: 768px) {
  .search-but{
    display: none;
  }
  .logon-but {
    font-size: 12px;
  }

  .login-but {
    width: 60px;
    text-align: center;
    font-size: 12px;
  }

  .search .el-input__inner::placeholder {
    font-size: 12px;
  }

  /**顶部 */
  .top-img {
    width: 25px;
    height: 15px;
  }

  .top-img img {
    width: 40px;
    height: 25px;
  }

  .top-title {
    font-size: 30px;
  }

  .top-name {
    margin: 0 23px 0 16px;
  }

  .jia {
    font-size: 20px;
    width: 2px;
    line-height: 26px;
    margin: 12px 20px 0 0;
  }

  .one {
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    margin: 0 10px 0 30px;
  }

  .shu {
    height: 25px;
  }

  .room-num {
    margin: 0 10px 0 10px;
    font-size: 18px;
    line-height: 25px;
  }

  .tow {
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 25px;
  }

  .thr {
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    margin-left: 10px;
  }

  /**弹出场景框 */
  .bom-top {
    overflow: hidden;
    width: 100%;
    height: 80px;
  }

  .bom-top-li {
    margin-left: 1px;
  }

  .li-box {
    width: 100px;
    height: 70px;
  }

  .li-img {
    width: 100px;
    height: 70px;
  }

  .li-img img {
    width: 100px;
    height: 70px;
  }

  .img-bom-title {
    font-size: 10px;
  }

  .conference {
    font-size: 12px;
  }

  .room-nub input::-webkit-input-placeholder {
    transform: translate(15px, 0);
  }

  .main-search {
    margin: 0 5px;
  }

  .login {
    margin-right: 10px;
  }
}

@media screen and (max-width: 705px) {
  .search-but{
    display: none;
  }
  .room-input {
    width: 223px;
    font-size: 60px;
  }

  .star-room-nub {
    width: 225px;
    font-size: 55px;
    padding-left: 50px;
    letter-spacing: 25px;
  }
}

@media screen and (max-width: 530px) {
  .search-but{
    position: relative;
    left: -9%;
    display: block;
  }
  #searchInput{
    display: none;
  }
  /* .search{
    display: none;
  } */
  .upload-but {
    display: none;
  }

  .logo-text {
    display: none;
  }
}
</style>
