import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    editText: "",
    cover: null,
    //全景图id
    sourId: "",
    panoramaArr: [],
    encrypt: 2,
    hotsArr:null,
    addHots: false,
    firstUpload:true,
    moveHots:false,
  },
  getters: {
    editText: (state) => state.editText,
    cover: (state) => state.cover,
    sourId: (state) => state.sourId,
    panoramaArr: (state) => state.panoramaArr,
    encrypt: (state) => state.encrypt,
    hotsArr: (state) => state.hotsArr,
    addHots: (state) => state.addHots,
    firstUpload: (state) => state.firstUpload,
    moveHots: (state) => state.moveHots,
  },
  mutations: {
    updateEditText(state, payload) {
      state.editText = payload;
    },
    updateCover(state, payload) {
      state.cover = payload;
    },
    updateSourId(state, payload) {
      state.sourId = payload;
    },
    updatePanoramaArr(state, payload) {
      state.panoramaArr = payload;
    },
    updateEncrypt(state, payload) {
      state.encrypt = payload;
    },
    updateHotsArr(state, payload) {
      state.hotsArr = payload;
    },
    updateAddHots(state, payload) {
      state.addHots = payload;
    },
    updateFirstUpload(state, payload) {
      state.firstUpload = payload;
    },
    updateMoveHots(state, payload) {
      state.moveHots = payload;
    },
  },
  actions: {
    setEditText(context, payload) {
      context.commit("updateEditText", payload);
    },
    setCover(context, payload) {
      context.commit("updateCover", payload);
    },
    setSourId(context, payload) {
      context.commit("updateSourId", payload);
    },
    setPanoramaArr(context, payload) {
      context.commit("updatePanoramaArr", payload);
    },
    setEncrypt(context, payload) {
      context.commit("updateEncrypt", payload);
    },
    setHotsArr(context, payload) {
      context.commit("updateHotsArr", payload);
    },
    setAddHots(context, payload) {
      context.commit("updateAddHots", payload);
    },
    setFirstUpload(context, payload) {
      context.commit("updateFirstUpload", payload);
    },
    setMoveHots(context, payload) {
      context.commit("updateMoveHots", payload);
    },
  },
 
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
