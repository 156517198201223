export default {
  meg: {
    top: {
      placeholder: "Search...",
      loginBut:"LOGIN",
      user:"Personal Center",
      logout:"Log out",
      upload:"Upload",
      join:"Join a room",
      meeting:"Meeting Room",
    },
    login:{
      Login:"Log In",
      or:"or",
      BomTopTitle:"Continue with email",
      BomTopText:"Don't have an account?",
      BomTopTextSpan:"Join for free",
      MainTopTitleEmail:"Email Address",
      MainTopTitleCode:"Code",
      RightVoxWeixinBoxText:"WeChat Login",
      RightVoxWeixinBoxTextScan:"Scan with WeChat",
      goLogin:'You need to log in to create a room'
    },
    logon:{
      LogonTopTitle:"Create Account",
      or:"or",
      BomTopTitle:"Register by email",
      BomTopText:"Already have an account?",
      BomTopTextSpan:"Sign in",
      MainTopTitleEmail:"E-mail address",
      MainTopTitleVerification:"Verification Code",
      TopTitleBoxButSpan:"Get verification code",
      TopTitleBoxButSpanS:" s Reacquire after",
      MainMiddleButBox:"Determine",
      MainTopTitle:"Nickname",
      MainTopTitlePass:"Password",
      BottomBoxBut:"Register",
    },
    Krpano:{
      ConferenceHotelText:"Create a room",
      StarTitleTextTitle:"You are hosting the meeting",
      StarTitleText:"Your team can join the meeting with the invitation code",
      StarButText:"STAR",
    },
    personalcenter:{
      PersonalUploadMainCoverImgText:"Optimal Size: 600×400",
      PersonalUploadMainCoverText:"Cover Image",
      Delete:"Delete",
      DeleteMsg:'Are you sure to delete this?',
      edit:'Edit',
      Cancel:"Cancel",
      complete:"Complete",
      Publish:"Publish",
      one:"Left-Right Layout：Aspect Ratio:",
      two:"Minimum File Size:",
      twwo:"Top-Bottom Layout：Aspect Ratio:",
      three:"Panoramas：Aspect Ratio:",
      Panoramic:"Panoramic",
      and:" and ",
      Stereoscopic:"Stereoscopic",
      Name:"NAME",
      eD:"3D",
      Description:"Description",
      images:" images are advised before upload. Max File Size is ",
      Personal:"Personal homepage",
      views:"Number of item views",
      About:"About me",
      Membership:"Membership Date:",
      Edit:"Edit personal information",
      works:"Works",
      Upload:"Upload works",
      EditiingInputName:"Enter nickname",
      EditiingInputCompany:"Affiliated company",
      EditiingInputWebsite:"Company or personal website",
      EditiingInputCity:"address",
      Img:'Picture',
      name:'Name' ,
      view:'View',
      replace:'Replace'
    },
    edit:{
      hotspot:'Hotspot',
      Sandtable:'Sand table',
      Visibility:'Visibility',
      prompt:'File upload prompt',
      perspective:'Perspective',
      uploadPromptCover:'Please upload the cover',
      uploadPromptWorks:'Please upload your works'
    }
  }
}