import { createApp } from "vue";
import router from "./router";
import Axios from "axios";
import ElementPlus from "element-plus";
import store from './store/index'
import "element-plus/dist/index.css";
import App from "./App.vue";
import { createPinia } from "pinia";
import { ref } from "vue";
import "@/font/font.css";
import baseURL from "../test.js";
import i18n from './language/index'
const pinia = createPinia();
router.beforeEach((to, from, next) => {
  AppNew.config.globalProperties.url.value = to.fullPath;
  if (to.meta.AuthPolicy !== undefined && to.meta.AuthPolicy === "NeedLogin") {
    //目标路由需要验证用户已登录
    if (sessionStorage.getItem("AccessToken") === null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  AppNew.config.globalProperties.url.value = to.fullPath;
  if (to.meta.AuthPolicy !== undefined && to.meta.AuthPolicy === "HomeLogin") {
    //目标路由需要验证用户已登录
    if (localStorage.getItem("v-token") === null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
//请求
Axios.interceptors.request.use(
  function (config) {
    if (!config.url.includes("http")) {
      config.url = baseURL.api + config.url;
    }
    // if (
    //   config.url.includes("admin") &&
    //   !config.url.includes("administrators")
    // ) {
    //   config.data = config.data ? config.data : {};
    //   if (config.method == "post") {
    //     config.data.token = localStorage.getItem("v-token");
    //   } else if (config.method == "get") {
    //     config.params.token = localStorage.getItem("v-token");
    //   } else if (config.method == "get"){
    //     config.headers.token2 = localStorage.getItem("v-token");
    //   }
    // }
    config.headers = {
      "v-token": localStorage.getItem("v-token"),
      "a-token": sessionStorage.getItem("AccessToken"),
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
//响应拦截
// Axios.interceptors.request.use((res)=>{
//     console.log(res);
//     if(res.data.code == 2000){
//         return res;
//     }else if(res.data.code == 3000){
//         localStorage.removeItem("v-Token");
//         router.push("/");
//         return Promise.reject(res.data.msg)
//     }
// },
// (err)=>{
//     if(err.request){
//         if(err.response.status == 404){
//             console.log("客户端异常请稍后再试");
//         }
//     }else{
//         console.log("网络异常");
//     }
// }
// )
const AppNew = createApp(App);
AppNew.use(router);
AppNew.use(store);
AppNew.use(ElementPlus);
AppNew.use(pinia);
AppNew.use(i18n);
AppNew.config.globalProperties.$Axios = Axios;
AppNew.config.globalProperties.url = ref("/");
AppNew.mount("#app");
