let baseURL = {}

if (process.env.NODE_ENV === 'production') {
    //dev 生产环境
    baseURL = {
        api: 'vimiu/',
        socket: "https://room.varecloud.com",
        socketIo: "https://upload.varecloud.com"
    }
} else {
    //dev 开发环境
    baseURL = {
        api: 'https://pano.123123123.work/vimiu/',
        socket: "https://live2.123123123.work",
        socketIo: "https://live.123123123.work"
    }

}
export default baseURL
